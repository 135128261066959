<template>
  <div class="py-4">
    <router-link to="/">
      <v-img src="/assets/fi-logo.png" height="80" width="240" contain></v-img>
    </router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>